.report-one__header {
  background: #f5f5f5;
  display: flex;
  border-top: 1px solid #dadada;
  min-height: 55px;
  flex-wrap: nowrap;
  padding: 4px 0; }

.report-one__header-member {
  font-size: 11px;
  color: #9B9B9B;
  text-transform: uppercase;
  flex: 0 0 21%;
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .report-one__header-member div {
    height: 13px; }

.report-one__header-countp {
  font-size: 11px;
  color: #9B9B9B;
  text-transform: uppercase;
  text-align: right;
  flex: 0 0 100px;
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .report-one__header-countp div {
    height: 13px; }

.report-one__header-cols {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-direction: column; }

.report-one__header-cols-title {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 11px;
  color: #9B9B9B; }

.report-one__header-cols-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  justify-content: center; }

.report-one__header-cols-subtitle {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 11px;
  color: #9B9B9B; }

.report-one__header-col {
  flex: 1;
  display: flex;
  align-self: flex-end;
  padding: 0 5px;
  align-items: center;
  justify-content: center; }

.report-one__header-meta {
  text-align: center; }

.report-one__header-col-title {
  font-size: 14px;
  color: #4A4A4A;
  text-transform: uppercase;
  font-weight: 500; }

.report-one__header-col-max {
  font-size: 12px;
  color: #9B9B9B;
  text-transform: uppercase;
  font-weight: 300; }

.report-one__header-sort-icon {
  display: none;
  flex-direction: column;
  width: 6px; }
  .report-one__header-sort-icon_up {
    margin: 3px 0 4px 3px; }
  .report-one__header-sort-icon_down {
    margin: 0 0 0 3px; }

.report-one__sum {
  display: flex;
  height: 42px;
  flex-wrap: nowrap;
  background: #e8e8e8;
  padding: 8px 0; }

.report-one__sum-member {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  flex: 0 0 21%;
  padding: 0 14px;
  border-right: 1px solid #fff; }

.report-one__sum-countp {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 100px;
  padding: 0 14px;
  border-right: 1px solid #fff; }

.report-one__sum-cols {
  display: flex;
  flex-wrap: nowrap;
  flex: 1; }

.report-one__sum-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  color: #000;
  font-weight: 300; }

.report-one__row {
  display: flex;
  border-radius: 0;
  min-height: 48px;
  flex-wrap: nowrap;
  background: #fff;
  padding: 8px 0;
  margin: -2px 0 0 0;
  box-shadow: inset 0px -3px 0px #e8e8e8; }

.report-one__row-member {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  flex: 0 0 21%;
  padding: 0 14px;
  line-height: 16px;
  border-right: 1px solid #dadada; }
  .report-one__row-member_bad {
    font-weight: bold;
    color: #d3000f; }

.report-one__row-checkbox {
  margin: -6px 13px 0 0;
  display: none; }

.report-one__row-countp {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 100px;
  padding: 0 14px;
  border-right: 1px solid #dadada; }
  .report-one__row-countp_bad {
    font-weight: bold;
    color: #d3000f; }

.report-one__row-cols {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  color: #000;
  font-weight: 300; }
  .report-one__row-cols_bad {
    font-weight: bold;
    color: #d3000f; }

.report-one__row-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 14px; }

.report-one__popover {
  max-width: 200px; }
