.schools_list__header {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  border-top: 1px solid #dadada;
  height: 80px;
  flex-wrap: nowrap;
  font-size: 12px;
  color: #9B9B9B;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  padding: 4px 0;
  font-size: 11px; }
  .schools_list__header--title {
    flex: 0 0 15%;
    border-right: 1px solid #dadada;
    padding: 0 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; }
  .schools_list__header--title:last-child {
    border-right: none; }

.schools_list__results {
  width: 100%;
  background: #fff;
  display: flex;
  border-top: 1px solid #dadada;
  height: 70px;
  flex-wrap: nowrap;
  font-size: 12px;
  color: #000;
  padding: 4px 0; }
  .schools_list__results--title {
    flex: 0 0 15%;
    border-right: 1px solid #dadada;
    padding: 0 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; }
  .schools_list__results--title:last-child {
    border-right: none; }

.ReactTable {
  padding: 0 10px; }
