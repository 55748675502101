.report-filter-members-popup {
  display: flex;
  flex-direction: row;
  width: 100%; }
  .report-filter-members-popup__col {
    border-right: 1px solid #ccc;
    height: 600px;
    display: flex;
    flex-direction: column;
    flex: 1; }
    .report-filter-members-popup__col:last-child {
      border-right: none; }
  .report-filter-members-popup__col-header {
    height: 36px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px; }
  .report-filter-members-popup__col-header-name {
    font-size: 12px;
    color: #000; }
  .report-filter-members-popup__col-content {
    flex: 1;
    overflow-y: auto; }
