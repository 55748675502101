.report-filter-list-item {
  display: flex;
  height: 26px;
  padding: 0 13px 0 23px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative; }
  .report-filter-list-item:hover {
    background: #e7f3fc; }
  .report-filter-list-item_selected {
    background: #f7f7f7; }
    .report-filter-list-item_selected:hover {
      background: #f7f7f7; }
  .report-filter-list-item__meta {
    display: flex;
    align-items: center; }
  .report-filter-list-item__code {
    width: 22px;
    font-size: 14px;
    color: #9b9b9b;
    font-weight: 300;
    padding: 0 14px 0 0; }
  .report-filter-list-item__name {
    font-size: 14px;
    color: #000;
    font-weight: 300;
    padding: 0 14px 0 0; }
  .report-filter-list-item__has-selected-children {
    position: absolute;
    margin-left: -10px;
    width: 5px;
    height: 5px;
    background: #00b3ad;
    border-radius: 50%; }
  .report-filter-list-item .ant-checkbox-wrapper {
    vertical-align: top !important; }
  .report-filter-list-item .ant-checkbox {
    vertical-align: top !important; }
