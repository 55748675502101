.report-filter-reports {
  position: relative; }
  .report-filter-reports__popup {
    background: #ffffff;
    border: 1px solid rgba(155, 155, 155, 0.3);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
    width: 340px;
    min-height: 130px;
    position: absolute;
    z-index: 1000;
    right: 0;
    margin-top: -5px;
    margin-right: 8px; }
    .report-filter-reports__popup_open {
      display: flex; }
    .report-filter-reports__popup_close {
      display: none; }
