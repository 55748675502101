body {
  background: #f5f5f5; }

.app {
  display: flex;
  min-height: 100vh; }

.ant-layout {
  background: transparent; }

.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden; }

.main {
  flex: 1;
  min-width: 600px; }

.body {
  flex-direction: row; }

.sider {
  background: #fff; }
  .sider__left {
    border-right: 1px solid #9b9b9b; }
  .sider__right {
    border-left: 1px solid #9b9b9b; }
