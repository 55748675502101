.report-filter-item {
  position: relative;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #D0D0D0;
  display: flex;
  align-items: center;
  padding: 0 10px 0 20px;
  cursor: pointer;
  margin: 0 10px;
  user-select: none; }
  .report-filter-item_is-open {
    border: 1px solid #D0D0D0;
    background: #fff; }
  .report-filter-item__name {
    font-size: 20px;
    font-weight: 300;
    margin: 0 20px 0 0;
    color: #000; }
  .report-filter-item__caret {
    font-size: 8px; }
    .report-filter-item__caret_up {
      color: #000; }
    .report-filter-item__caret_down {
      color: #9b9b9b; }
  .report-filter-item__bubble {
    color: #fff;
    height: 22px;
    border-radius: 22px;
    min-width: 22px;
    font-size: 12px;
    line-height: 23px;
    background: #00B0AD;
    text-align: center;
    font-weight: bold;
    position: absolute;
    right: 3px;
    top: -8px; }
  .report-filter-item_disabled {
    pointer-events: none;
    background-color: #f5f5f5;
    background: whitesmoke; }
    .report-filter-item_disabled .report-filter-item__name {
      color: rgba(0, 0, 0, 0.25); }
    .report-filter-item_disabled .report-filter-item__bubble {
      background-color: #9b9b9b; }
