.sort-item {
  cursor: pointer;
  padding: 0 8px 0 8px;
  border-right: 1px solid #ccc;
  display: flex;
  align-items: center; }
  .sort-item:last-child {
    border-right: none;
    padding: 0 0 0 8px; }
  .sort-item:first-child {
    padding: 0 8px 0 0; }
  .sort-item__text {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    padding: 0 3px 0 0; }
  .sort-item__derection {
    font-size: 12px;
    color: #00b0ad;
    display: none; }
  .sort-item_active .sort-item__text {
    font-weight: bold; }
  .sort-item_active .sort-item__direction {
    display: inline-block;
    font-size: 12px;
    color: #00b0ad; }
