.procedure-tag {
  border: 1px solid #00B0AD;
  background: #f7f7f7;
  height: 23px;
  border-radius: 4px;
  margin: 3px 3px 3px 0;
  padding: 3px 4px 0;
  color: #000;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .procedure-tag:last-child {
    margin-left: 0; }
  .procedure-tag__remove {
    cursor: pointer;
    color: #8c8c8c;
    margin: 0 0 0 3px; }
    .procedure-tag__remove:hover {
      color: #00B0AD; }
