.schools-list {
  padding-top: 12px; }
  .schools-list__wrapper-spin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center; }
  .schools-list__spin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
