.report-five {
  border: 1px solid #d0d0d0;
  background: #fff;
  padding: 10px 0 10px;
  margin-bottom: 20px; }
  .report-five__name {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 14px; }
    .report-five__name_bad {
      font-weight: bold;
      color: #d3000f; }
  .report-five__no-data {
    font-size: 12px;
    color: #333;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 14px; }
  .report-five__no-data-all {
    padding: 0 14px; }
  .report-five__no-data {
    text-align: center;
    padding: 14px 14px 0;
    display: flex;
    justify-content: center; }
  .report-five__tooltip {
    background: #fff;
    padding: 0 8px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #d0d0d0; }
  .report-five__tooltip-item {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    text-align: center; }
  .report-five .recharts-tooltip-wrapper {
    color: #000 !important; }
  .report-five .recharts-cartesian-axis-tick-line {
    display: none; }
  .report-five .recharts-cartesian-axis-line {
    display: none; }
