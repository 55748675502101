.directory-filter-item {
  position: relative;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #D0D0D0;
  display: flex;
  align-items: center;
  padding: 0 10px 0 20px;
  cursor: pointer;
  margin: 0 10px;
  user-select: none; }
  .directory-filter-item_is-open {
    border: 1px solid #D0D0D0;
    background: #fff; }
  .directory-filter-item__name {
    font-size: 20px;
    font-weight: 300;
    margin: 0 20px 0 0;
    color: #000; }
  .directory-filter-item__caret {
    font-size: 8px; }
    .directory-filter-item__caret_up {
      color: #000; }
    .directory-filter-item__caret_down {
      color: #9b9b9b; }
