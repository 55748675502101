.loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px; }

.content {
  display: flex;
  justify-content: center; }

.reports-list {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-height: 200px; }
  .reports-list__inner {
    min-width: 800px; }
