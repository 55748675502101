.report-filter-submit {
  display: flex;
  height: 50px;
  padding: 0 20px;
  margin: 0 0 0 10px;
  color: #d0d0d0;
  border: 1px dashed #d0d0d0;
  justify-content: center;
  font-size: 22px;
  border-radius: 4px;
  line-height: 50px; }
  .report-filter-submit_valid {
    cursor: pointer;
    background: #e7f3fc;
    border: 1px solid #108ee9;
    color: #108ee9; }
