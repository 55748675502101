.districts-list {
  padding-top: 12px; }
  .districts-list__wrapper-spin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center; }
  .districts-list__spin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.report-list {
  padding-top: 12px; }
  .report-list__line {
    background-color: #ccc;
    width: 100%;
    height: 1px;
    margin-bottom: 5px; }
