.report-title {
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  font-size: 28px;
  color: #000;
  margin-bottom: 24px; }
  .report-title__name {
    white-space: nowrap; }
  .report-title__members {
    padding: 0 0 0 20px;
    font-size: 10px;
    font-weight: 300;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
