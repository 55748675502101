.report-seven {
  border: 1px solid #d0d0d0;
  background: #fff;
  padding: 10px 0 10px;
  margin-bottom: 20px; }
  .report-seven__name {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 14px; }
    .report-seven__name_bad {
      font-weight: bold;
      color: #d3000f; }
  .report-seven__no-data {
    font-size: 12px;
    color: #333;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 14px; }
  .report-seven__no-data-all {
    padding: 0 14px; }
  .report-seven__no-data {
    text-align: center;
    display: flex;
    justify-content: center; }
  .report-seven__tooltip {
    background: #fff;
    padding: 0 8px;
    min-height: 32px;
    display: flex;
    flex-direction: column;
    border: 1px solid #d0d0d0; }
  .report-seven__tooltip-row {
    display: flex; }
  .report-seven__tooltip-title {
    padding: 3px 0 4px;
    font-size: 13px;
    font-weight: bold; }
  .report-seven__tooltip-item {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    white-space: nowrap; }
  .report-seven__tooltip-value {
    font-size: 12px;
    color: #000; }
  .report-seven__tooltip-separator {
    color: #d0d0d0;
    padding: 0 5px;
    font-size: 14px;
    text-align: center; }
  .report-seven .recharts-tooltip-wrapper {
    color: #000 !important; }
  .report-seven .recharts-cartesian-axis-tick-line {
    display: none; }
  .report-seven .recharts-cartesian-axis-line {
    display: none; }
