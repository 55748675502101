.report-filter-reports-popup {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .report-filter-reports-popup__header {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: flex-end;
    min-height: 36px;
    padding: 0 13px 0 23px;
    align-items: center; }
