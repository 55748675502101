.report-filter {
  display: flex;
  height: 70px;
  width: 100%;
  background: #eaeaea;
  border-bottom: 1px solid #d0d0d0;
  align-items: center;
  justify-content: center;
  padding: 0 8px; }
  .report-filter__col {
    display: flex;
    max-width: 1400px;
    width: 100%; }
  .report-filter__title {
    display: flex;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    color: #9B9B9B;
    align-items: center; }
