.reports-list {
  font-family: 'Roboto', sans-serif !important;
  max-width: 1400px;
  width: 100%;
  padding: 0 8px; }
  .reports-list__item {
    margin-top: 56px; }
    .reports-list__item:first-child {
      margin-top: 0; }
    .reports-list__item_small {
      margin-top: 46px; }
      .reports-list__item_small:first-child {
        margin-top: 0; }
