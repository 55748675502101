.report-filter-procedures {
  position: relative; }
  .report-filter-procedures__popup {
    background: #ffffff;
    border: 1px solid rgba(155, 155, 155, 0.3);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
    width: 570px;
    height: 200px;
    position: absolute;
    z-index: 1000;
    margin-top: -5px;
    margin-left: 10px; }
    .report-filter-procedures__popup_open {
      display: flex; }
    .report-filter-procedures__popup_close {
      display: none; }
