.report-four__container {
  z-index: 1; }

.report-four__header {
  background: #f5f5f5;
  display: flex;
  border-top: 1px solid #dadada;
  height: 90px;
  flex-wrap: nowrap;
  font-size: 11px;
  color: #9B9B9B;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  padding: 4px 0; }

.report-four__header-member {
  flex: 1;
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }

.report-four__header-countp {
  text-align: right;
  flex: 0 0 110px;
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .report-four__header-countp div {
    height: 13px; }

.report-four__header-balls {
  display: flex;
  flex: 0 0 30%;
  flex-direction: column;
  padding: 0 14px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dadada; }

.report-four__header-chart {
  display: flex;
  flex: 0 0 160px;
  flex-direction: column;
  padding: 0 14px;
  align-items: center;
  justify-content: center;
  text-align: center; }

.report-four__header-balls-cols {
  display: flex;
  width: 100%;
  padding: 5px 0 0 0; }

.report-four__header-balls-col {
  display: flex;
  padding: 0 7px;
  font-size: 14px;
  color: #4A4A4A;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center; }

.report-four__sum {
  display: flex;
  height: 42px;
  flex-wrap: nowrap;
  background: #e8e8e8;
  padding: 8px 0 0;
  padding: 8px 0;
  font-size: 14px;
  color: #000;
  font-weight: 300; }

.report-four__sum-member {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 14px;
  font-size: 16px;
  font-weight: 400;
  border-right: 1px solid #fff; }

.report-four__sum-countp {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 110px;
  padding: 0 14px;
  border-right: 1px solid #fff; }

.report-four__sum-cols {
  display: flex;
  flex: 0 0 30%;
  padding: 0 14px;
  align-items: center;
  justify-content: center; }

.report-four__sum-col {
  display: flex;
  padding: 0 7px;
  flex: 1;
  align-items: center;
  justify-content: center; }

.report-four__sum-chart {
  display: flex;
  flex: 0 0 160px;
  flex-direction: column;
  padding: 0 14px;
  align-items: center;
  justify-content: center;
  text-align: center; }

.report-four__row {
  display: flex;
  min-height: 48px;
  flex-direction: column;
  background: #fff;
  border-bottom: 1px solid #e8e8e8; }

.report-four__row-data {
  flex: 1;
  display: flex;
  min-height: 48px;
  background: #fff;
  padding: 8px 0; }

.report-four__row-member {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 14px;
  border-right: 1px solid #dadada; }
  .report-four__row-member_bad {
    font-weight: bold;
    color: #d3000f; }
  .report-four__row-member_empty-data {
    border-right: none; }

.report-four__row-checkbox {
  display: none; }

.report-four__row-countp {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 110px;
  padding: 0 14px;
  border-right: 1px solid #dadada; }
  .report-four__row-countp_bad {
    font-weight: bold;
    color: #d3000f; }

.report-four__row-cols {
  color: #000;
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 30%;
  padding: 0 14px;
  border-right: 1px solid #dadada;
  font-weight: 300; }
  .report-four__row-cols_bad {
    font-weight: bold;
    color: #d3000f; }

.report-four__row-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 14px; }

.report-four__row-chart {
  display: flex;
  flex: 0 0 160px;
  padding: 0 14px;
  align-items: center;
  justify-content: center; }

.report-four__row-chart-icon {
  width: 12px;
  cursor: pointer; }

.report-four__row-chart-item {
  height: 22px;
  border-bottom: 1px solid #d0d0d0;
  margin: 0 24px 0 0; }

.report-four__row-no-data {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.report-four__row-big-chart {
  padding: 20px; }

.report-four__row-big-chart-inner {
  margin: 0 0 0 105px;
  padding: 25px;
  background: #f3faff; }

.report-four .recharts-cartesian-axis-tick-line {
  display: none; }

.report-four .recharts-cartesian-axis-line {
  display: none; }

.report-four__tooltip {
  background: #fff;
  padding: 0 8px;
  height: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #d0d0d0; }

.report-four__tooltip-item {
  font-size: 12px;
  font-weight: bold;
  color: #000;
  text-align: center;
  white-space: nowrap; }

.report-four__tooltip-separator {
  color: #d0d0d0;
  padding: 0 5px;
  font-size: 14px;
  text-align: center; }
