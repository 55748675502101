.report-filter-procedures-popup {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .report-filter-procedures-popup__tags {
    min-height: 36px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .report-filter-procedures-popup__tags span {
      align-self: center;
      font-size: 13px;
      font-weight: 300;
      margin-right: 5px; }
  .report-filter-procedures-popup__cols {
    display: flex;
    flex: 1;
    min-height: 0; }
  .report-filter-procedures-popup__col {
    min-width: 100px;
    height: 100%;
    border-right: 1px solid #ccc;
    overflow-y: auto; }
    .report-filter-procedures-popup__col:last-child {
      border-right: none;
      flex: 1; }
