.header {
  background: #393e4a;
  display: flex;
  justify-content: center;
  padding: 0; }
  .header__content {
    max-width: 1500px;
    padding: 0 8px;
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .header__right {
    display: flex;
    align-items: center;
    margin-left: auto; }
  .header__left {
    display: flex;
    align-items: center;
    flex-basis: 90%; }
  .header__user {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #fff; }
    .header__user_name {
      margin-right: 10px;
      user-select: none;
      color: #c9cddc; }
  .header__user-icon {
    font-size: 26px;
    color: #ffffff; }

.logo {
  font-family: "Circe Extra", sans-serif;
  font-weight: 800;
  color: #fff;
  font-size: 20px;
  line-height: 64px;
  flex-basis: 10%; }
  .logo__href {
    color: #fff;
    font-family: "Circe Extra", sans-serif;
    text-decoration: none; }
    .logo__href:hover {
      color: #fff;
      text-decoration: none; }

.menu {
  line-height: 30px;
  display: flex;
  outline: none;
  list-style: none;
  margin: 0;
  padding-inline-start: 20px;
  align-items: center;
  flex-basis: 90%;
  justify-content: space-between; }
  .menu-item {
    text-transform: uppercase;
    cursor: pointer;
    background-color: #393e4a;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 300;
    color: #c9cddc; }
    .menu-item a {
      transition: color 200ms linear 0s;
      color: #c9cddc;
      text-decoration: none;
      height: 64px; }
    .menu-item-selected {
      font-weight: bold;
      cursor: auto; }
      .menu-item-selected a {
        color: #ffffff;
        text-decoration: none; }
    .menu-item-active a {
      color: #ffffff;
      text-decoration: none; }
