.directory-filter-options-popup {
  display: flex;
  flex-direction: row;
  width: 100%; }
  .directory-filter-options-popup__button {
    display: flex;
    margin: 25px 0;
    justify-content: flex-end;
    margin-left: auto; }
  .directory-filter-options-popup__col {
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    flex: 1; }
    .directory-filter-options-popup__col:last-child {
      border-right: none; }
  .directory-filter-options-popup__col-header {
    min-height: 40px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px;
    color: #d0d0d0;
    font-weight: 300;
    font-size: 15px; }
  .directory-filter-options-popup__col-header-name {
    font-size: 12px;
    color: #000; }
  .directory-filter-options-popup__col-content {
    flex: 1;
    overflow-y: auto; }
  .directory-filter-options-popup__content {
    padding: 23px 23px 0px 23px; }
    .directory-filter-options-popup__content__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; }
      .directory-filter-options-popup__content__item--header {
        font-weight: bold; }
      .directory-filter-options-popup__content__item--min {
        overflow-y: scroll;
        max-height: 200px; }
    .directory-filter-options-popup__content__options {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
      .directory-filter-options-popup__content__options--header {
        font-weight: bold; }
    .directory-filter-options-popup__content_features {
      display: flex;
      align-items: flex-start;
      flex-direction: column; }
      .directory-filter-options-popup__content_features .ant-checkbox-wrapper {
        margin-left: 0; }
