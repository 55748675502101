.report-six__header {
  display: flex;
  border-top: 1px solid #dadada;
  min-height: 55px;
  flex-wrap: nowrap;
  padding: 4px 0; }

.report-six__header-member {
  font-size: 11px;
  color: #9B9B9B;
  text-transform: uppercase;
  flex: 0 0 21%;
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .report-six__header-member div {
    height: 13px; }

.report-six__header-countp {
  font-size: 11px;
  color: #9B9B9B;
  text-transform: uppercase;
  text-align: right;
  flex: 0 0 100px;
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .report-six__header-countp div {
    height: 13px; }

.report-six__header-cols {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-direction: column; }

.report-six__header-cols-title {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 11px;
  color: #9B9B9B; }

.report-six__header-cols-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  justify-content: center; }

.report-six__header-cols-subtitle {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 11px;
  color: #9B9B9B; }

.report-six__header-col {
  flex: 1;
  display: flex;
  align-self: flex-end;
  padding: 0 5px;
  align-items: center;
  justify-content: center; }

.report-six__header-meta {
  text-align: center; }

.report-six__header-col-title {
  font-size: 14px;
  color: #4A4A4A;
  text-transform: uppercase;
  font-weight: 500; }

.report-six__header-col-max {
  font-size: 14px;
  color: #9B9B9B;
  text-transform: uppercase;
  font-weight: 300; }

.report-six__header-sort-icon {
  display: none;
  flex-direction: column;
  width: 6px; }
  .report-six__header-sort-icon_up {
    margin: 3px 0 4px 3px; }
  .report-six__header-sort-icon_down {
    margin: 0 0 0 3px; }

.report-six__sum {
  display: flex;
  height: 42px;
  flex-wrap: nowrap;
  background: #e8e8e8;
  margin: 8px 0 0;
  padding: 8px 0; }

.report-six__sum-member {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  flex: 0 0 21%;
  padding: 0 14px;
  border-right: 1px solid #fff; }

.report-six__sum-countp {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 100px;
  padding: 0 14px;
  border-right: 1px solid #fff; }

.report-six__sum-cols {
  display: flex;
  flex-wrap: nowrap;
  flex: 1; }

.report-six__sum-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  color: #000;
  font-weight: 300; }

.report-six__row {
  display: flex;
  border-radius: 0;
  min-height: 48px;
  flex-wrap: nowrap;
  background: #fff;
  padding: 8px 0;
  margin: -2px 0 0 0;
  box-shadow: inset 0px -3px 0px #e8e8e8;
  flex-direction: column; }

.report-six__row-main {
  display: flex;
  flex: 1; }

.report-six__row-member {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  flex: 0 0 21%;
  padding: 0 14px;
  border-right: 1px solid #dadada; }
  .report-six__row-member_bad {
    font-weight: bold;
    color: #d3000f; }

.report-six__row-middle-col-name {
  font-size: 14px;
  font-weight: 300;
  color: #000;
  display: flex;
  align-items: center;
  flex: 0 0 21%;
  padding: 0 14px 0 41px;
  border-right: 1px solid #dadada;
  margin: 2px 0; }

.report-six__row-checkbox {
  display: none;
  margin: -6px 13px 0 0; }

.report-six__row-countp {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  flex: 0 0 100px;
  padding: 0 14px;
  border-right: 1px solid #dadada;
  margin: 2px 0; }

.report-six__row-cols {
  display: flex;
  flex-wrap: nowrap;
  flex: 1; }

.report-six__row-col {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
  color: #000;
  font-weight: 300;
  margin: 2px 0; }

.report-six__chart-title {
  margin: 24px 41px 0;
  font-size: 14px;
  font-weight: bold; }

.report-six__row-chart {
  padding-top: 5px; }

.report-six .recharts-cartesian-axis-tick-line {
  display: none; }

.report-six .recharts-cartesian-axis-line {
  display: none; }

.report-six__no-data {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 1; }

.report-six__tooltip {
  background: #fff;
  padding: 0 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0d0; }
