.procedure-title {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  color: #000; }
  .procedure-title__col {
    display: flex;
    align-items: center; }
  .procedure-title__href-separator {
    font-size: 16px;
    color: #D0D0D0;
    padding: 0 10px; }
  .procedure-title__href {
    font-size: 16px;
    color: #4A4A4A;
    text-decoration: underline; }
    .procedure-title__href:hover {
      color: #0071BB;
      text-decoration: underline; }
  .procedure-title__name {
    font-weight: bold;
    padding: 0 3px 0 0; }
  .procedure-title__year {
    font-weight: bold;
    padding: 0 3px; }
  .procedure-title__separator {
    padding: 0 3px;
    color: #4A4A4A; }
  .procedure-title__level {
    padding: 0 3px;
    color: #4A4A4A; }
  .procedure-title__subject {
    padding: 0 0 0 3px;
    margin: 0 10px 0 0;
    color: #4A4A4A; }
