.directory-proc-filter-popup {
  height: 330px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 15px; }
  .directory-proc-filter-popup .report-filter-procedures-popup__col {
    max-height: 200px; }
  .directory-proc-filter-popup .report-filter-procedures-popup {
    height: 200px; }
  .directory-proc-filter-popup .report-filter-procedures-popup__tags {
    max-height: 100px;
    overflow-y: auto; }
