.directory__container {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .directory__container--schools {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 0;
    overflow-x: auto; }
  .directory__container--title {
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #000;
    padding: 0 10px;
    justify-content: space-between;
    background-color: #eaeaea; }

.directory-container__buttons {
  display: flex; }
  .directory-container__buttons_group {
    margin-right: 20px; }
