.directory-filter {
  display: flex;
  min-height: 70px;
  width: 100%;
  background: #eaeaea;
  border-bottom: 1px solid #d0d0d0;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px; }
  .directory-filter__col {
    display: flex;
    max-width: 1400px;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 0;
    align-items: center; }
  .directory-filter__title {
    display: flex;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    color: #9B9B9B;
    align-items: center; }
  .directory-filter__select {
    padding: 4px; }
  .directory-filter__select-name {
    padding: 0 0 0 7px;
    width: 200px; }
  .directory-filter__btns {
    display: flex;
    align-items: flex-end;
    padding: 4px; }
  .directory-filter__popup {
    background: #ffffff;
    border: 1px solid rgba(155, 155, 155, 0.3);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
    width: 500px;
    position: absolute;
    z-index: 1000;
    margin-top: -5px;
    margin-left: 10px;
    display: flex;
    max-height: 700px;
    overflow-y: auto; }
    .directory-filter__popup_proc {
      width: 600px; }
