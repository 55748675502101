.report-two__header {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  border-top: 1px solid #dadada;
  min-height: 62px;
  flex-wrap: nowrap;
  font-size: 11px;
  color: #9B9B9B;
  text-transform: uppercase;
  padding: 8px 7px 0; }

.report-two__header-num {
  display: flex;
  align-items: center;
  flex: 0 0 60%;
  padding: 0 7px;
  border-right: 1px solid #e8e8e8; }

.report-two__header-max {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  flex: 0 0 10%;
  border-right: 1px solid #e8e8e8; }

.report-two__header-balls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7px;
  flex: 0 0 30%;
  text-align: center; }

.report-two__header-balls-cols {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0; }

.report-two__header-balls-col {
  display: flex;
  align-items: center;
  padding: 0 7px;
  flex: 1;
  justify-content: center; }

.report-two__container {
  background: #fff;
  padding: 8px;
  min-height: 60px; }

.report-two__sum {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 70px;
  border-bottom: 1px solid #dadada; }
  .report-two__sum--member {
    flex: 0 0 60%;
    font-size: 15px;
    font-weight: bold;
    color: black;
    letter-spacing: 0.5px; }
  .report-two__sum--max {
    flex: 0 0 10%; }
  .report-two__sum--balls {
    flex: 0 0 30%;
    display: flex;
    justify-content: space-evenly; }
    .report-two__sum--balls-box {
      border: 1px solid #dadada;
      border-radius: 4px;
      padding: 4px 10px; }
    .report-two__sum--balls-title {
      font-size: 12px; }
    .report-two__sum--balls-number {
      font-size: 15px;
      font-weight: bold;
      color: black; }

.report-two__sum-balls-cols {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1; }

.report-two__sum-balls-col {
  display: flex;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  flex: 0 0 200px; }

.report-two__rows {
  margin-bottom: 20px;
  box-shadow: inset 0px -1px 0px #e8e8e8;
  background: #fff;
  font-size: 12px;
  color: #000;
  font-weight: 300;
  padding: 0 30px; }

.report-two__row {
  display: flex;
  padding: 10px 7px;
  justify-content: center;
  border-bottom: 1px solid #eaeaf0; }
  .report-two__row-num, .report-two__row-max, .report-two__row-balls {
    color: black;
    font-weight: bold; }

.report-two__row-num {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  padding: 0 7px;
  align-self: flex-start; }

.report-two__row-task {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 7px; }

.report-two__row-max {
  display: flex;
  align-items: center;
  padding: 0 7px;
  justify-content: center;
  flex: 0 0 10%; }

.report-two__row-balls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  flex: 0 0 15%; }

.report-two__row-empty {
  text-align: center; }
