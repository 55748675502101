.ReactTable {
  border-left: none;
  border-right: none;
}

.rt-tr-group {
  background: #fff;
  font-size: 12px;
  color: #000;
  padding: 4px 0;
}

.rt-td {
  border-color: #dadada !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_right {
    text-align: right;
  }
}

.-header {
  background-color: #f5f5f5;
  display: flex;
  border-bottom: 1px solid #dadada;
  height: 40px;
  flex-wrap: nowrap;
  font-size: 12px;
  color: #9b9b9b;
  letter-spacing: .75px;
  text-transform: uppercase;
  padding: 4px 0;
  font-size: 11px;
}

.-cursor-pointer {
  border-right: 1px solid #dadada;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.-odd .-even {
  background-color: #fff;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  white-space: unset;
}

.-header {
  height: auto;
}