.signin-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.signin {
  width: 600px;
  height: 420px;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .signin__error {
    margin-bottom: 20px; }

.signin-header {
  background: #393e4a;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 80px;
  align-items: center; }
  .signin-header__title {
    font-family: "Circe Extra", sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    line-height: 24px; }

.signin-content {
  flex: 1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0 140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%; }

.login-form-button {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  color: black; }
